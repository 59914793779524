/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";
@media all and(max-width: 1023px) {
	
	.wrapper, .container {width: 650px;} 
	
	.btn {
		&.more {
			font-size: 13px; padding: 0 0 12px 0;  
			&.white {font-size: 14px; letter-spacing: 1px;}  
		}
		&.blue {height: 30px; line-height: 30px;} 
	}
	.btn-download{
		padding: 0 100px 0 80px;
	}

	.side-nav {		
		.fl {
			padding: 0 60px 0 60px;
			.as-nav {
				ul {
					width: 215px; 
					li {
						a {padding: 15px 22px 15px 0; font-size: 22px;}  
						ul {
							padding: 0 0 0 55px; 
							&:before {width:40px;}     
						}
					}
				}
			}
		}
		.fr {width: 60px; right: -60px;}  
		.burger-nav {margin: 30px 0 0 16px;}   
	}
	
	header {
		.logo {
			a {width: 210px; height: 38px; background-size: 100% !important;}   
		}
		.box-search {
			input {
				&[type="text"] {width:180px; height: 35px;} 
				&[type="submit"] {width: 35px; height: 35px;}    
			}
		}
		.box-lang {
			.selectLang {
				select {height: 35px;}
			}
		}
	}

	.middle {
		.container {width: 615px;}  
		.colbox {			
			h3 {
				margin: 0 0 25px 0; line-height: 40px; font-size: 28px;  
			}
			h4 {font-size: 20px; margin-bottom: 15px;}  
			&.v2 {
				h2 {
					span { 	
						padding: 0 0 0 44px;				
						&:before {width: 30px;}		
					}	
				}
				h3 {font-size: 26px; line-height: 28px; margin-bottom: 18px;}   
			}   
			.des {
				font-size: 15px; line-height: 20px;
				&.v2{
					height: 41px; margin-bottom: 17px;
				}
			}
		}
		.box-tab {
			.nav-tab {margin-bottom: 30px;}   
		}
		.banner {
			.caption {
				padding: 0 30px;   
				.small {margin-bottom: 20px;}   
				.title {font-size: 50px; line-height: 55px; margin-bottom: 20px;}  
				.des {padding: 0 0; font-size: 15px; line-height: 26px;}  
			}
			.slick-initialized {
				.slick-dots {
					padding: 0 20px; bottom: 30px;  
					li {
						width: 45px; height: 45px;
						button {font-size: 11px;}  
						&.slick-active {
							button {width: 25px; height: 25px;} 
						}
					} 
				}
			}
			&.v2 {
				.caption {
					padding: 0 20px;   
					.small {margin-bottom: 15px;}   
					.title {font-size: 40px; line-height: 45px; margin-bottom: 20px;}  
					.des {padding: 0 0; font-size: 15px; line-height: 26px;}  
				}
			}
		}
		.home-about { 
			background-size: cover !important; 
			.container {
				padding: 70px 0 20px 0;   
				flex-flow: column;
			    display: -webkit-flex;
			    display: flex;
			    -webkit-flex-flow: column
			} 
			.col {
				display: block; position: relative; 
				&.img {
					order: 2; -webkit-order:2; width: 100%; padding: 0;
					margin-top: -150px; right: -120px;    
					img {width: 80%;}  
				} 
				&.text {
					order: 1; -webkit-order:1; width: 100%; padding: 0; 
					padding: 0 140px 0 0;  
				}   
			}
		}  
		.box-contact {
			height: auto; 
			&.page-contact {
				.container {width: 615px;} 
			}
			.cl {
				&.text {width: 100%; padding-bottom: 25px;}   
			}
			.map {
				width: 100%; height: 350px; position: relative; 
				.info_content {
					width: 200px; 
				}
				.content_map {
					width: 200px;
					.siteContent {
						h4 {font-size: 16px; line-height: 18px;}
					}
				}				
			}
			.list_cabang {
				.col {
					font-size: 14px; 
					a {line-height: 18px; display: block; padding: 2px 0;}  
				}
			}
		}
		.col-corp {
			.fl {
				.col {					
					h4 {font-size: 11px;} 
					.img {
						height: 40px;
						img {height: 100%; width: auto;}   
					} 
					&:first-child { 
						.img {							
							img {
								width: 70%; height: 23px; margin: auto; 
							}  
						}	
					}
				}  
			}
			.fr {
				.col {					 
					.img {
						width: 60%; height: 50%; margin: auto; top: 0; bottom: 0; left: 0; right: 0; position: absolute; 
						img {}   
					}
				}
			}
		}
		.midcontent {
			font-size: 15px; 
			p {line-height: 22px; margin-bottom: 28px;}   
			.container {width: 615px;} 
			.inner {padding: 40px;} 
			h5 {font-size: 20px;}

			&.v3,
			&.v4 {
				.container {width: 615px; padding: 50px 0 50px 0;}     
			}	

			.box-img {
				&.play {
					height: 278px;
					.img {
						&:after {
							width:46px; height: 46px;    
						}
					}
				} 
			}
			.box-vimis {
				padding-bottom: 0;   
				.row {
					margin: 0 0 45px 0; padding: 0 0 45px 0;    
					.col {
						&.vdes {
							padding-left: 30px;
							ul {margin-left: 20px;} 
						} 
					}
				}
			}
			.col-milestone {
				.row {
					.col {
						width: 120px;
						&:before {height:77%;} 
						.txt {
							/*width: 165px; */
							width: 100%;   
							font-size: 13px; margin: 0 0 10px 0;
							.mile-ico {
								width: 39px; height: 39px; margin-left: 15px;
								span {width: 100%; height: 100%;}  
							}  
							.mile-year {font-size: 36px; margin-left: 0;}   
							.mile-des {margin-left: 15px;} 
						}
						&:nth-child(even) { 
							.txt {
								.mile-ico {margin-top: 0px;}   
							}
						}
					}
					.slick-list {}
				}
			}
			&.career-detail {
				.container {padding: 50px 0;} 
				table {width: 100%;} 
			}
		}

		.list-row {
			&.v2 {				 
				.container {
					width: 615px;
				}
				.row {margin:0 0 35px 0; padding-bottom: 35px;} 
				.col {
					&.text {padding: 0 0 0 25px;}
 				}
			}
			&.v3 {
				.container { 
					flex-flow: column;
			    	display: -webkit-flex;
			    	display: flex;
			    	-webkit-flex-flow: column
				}
			    .col {
			    	display:block; float: none;
			    	&.img {
			    		width: 80%; padding: 0; order: 2; -webkit-order:2;
			    	}
			    	&.text {
			    		width: 100%; padding: 0; order: 1; -webkit-order:1;
			    	}
			    }
			}
			&.business {
				.row {
					margin-bottom: 60px; 
					.col {
						&.text {padding: 0 0 0 30px;}
						h3 {font-size: 26px; line-height: 28px; margin-bottom: 20px;}           
						.des {
							font-size: 14px; line-height: 18px; 
						}
					}
				}
			}
		}
		
		.list-manag {
			.col {
				padding: 0 25px 0 0; 
				.img {width: 100%; margin: 0 0 10px 0;} 
				.name {font-size: 24px; line-height: 26px; height: 60px;} 
				.position {font-size: 14px; margin: 0 0 10px 0; line-height: 16px;}   
				&:nth-child(2n+2) {
					padding: 0 0 0 25px; 
				}
			}
		}
		
		.col-achievement {
			.col {
				height: 213px; 
				&:before {border-width:1px;} 
				.inn {
					margin: 0 15px 0 27px; padding: 40px 0 0 0; 
					.title {font-size: 20px;}
					.year {
						margin: 0 0 15px 0; font-size: 12px; padding: 0 0 0 30px;
						&:before {width:20px;} 
					}
					.btn {bottom: 20px;}  
				}
			}
		}

		.filtering {  
			.fl {width: 50%;}      
			.fr {
				width: 50%;
				.fsearch {
					min-width: 100%; 
					input {
						&[type="text"] {font-size: 14px;}      
					}
				}
			}      
			&.bus-detail { 
				margin-bottom: 30px; 
				label {display: none;}  
			} 
		}
		.list-csr {
			.col {
				.date {
					font-size: 17px; right: 10px; padding: 17px 10px;   
					span {font-size: 13px; line-height: 16px;}         
				}
				.caption {
					h4 {font-size: 20px;}  
				}
			}
		}

		.our-product {
			.row {
				h3 {
					font-size: 23px; padding-left: 65px; 
					&:before {width: 40px;}  
				} 
				.box {
					.col {
						&.text {
							min-height: 177px; max-height: 177px; 
							.title {margin-bottom: 10px;} 
							p {font-size: 14px; line-height: 22px; margin-bottom: 10px;}  
							ul {
								li {line-height: 20px;}   
							}
						}
						&.gallery {
							width: 48.8%; position: relative; margin-bottom: 0px;
							.galSlider {margin:0 0 5px 0;} 							
						}
						&.text {width: 51.2%;} 
					}
					.thumbSlider {
						/*position: absolute; left: 0; bottom: 0;*/
						/*display: none; */
						width: 48.8%;
						.thumb {width: 62px; height: 62px;}   
					}
				}				
			}
		}
		
		.box-contact {
			&.page-contact {
				.container {
					padding: 50px 0;
				}
				.row {
					padding: 0; 
					&:before {display:none;}     
				}
				.cl {
					&.text {
						.box-tab {
							@include clearfix(); 
						}
						width: 100%; padding: 0; min-height: 150px;        
						.nav-tab {							 
							position: relative; left: 0; top: 0; z-index: 151; width: 30%; float: left;
							padding-right: 20px; 
							@include boxSizing(border-box);
							ul {
								li {
									display: block; margin-bottom: 0; 
								}
							}
						}
						.tab-container {
							padding: 0 0 0 0; float: right; width: 70%;
						}
					}
					&.form {
						width: 100%; padding: 0;
						label {margin-bottom: 5px; font-size: 14px;} 
						input {
							height: 30px; line-height: 30px; font-size: 14px;
						}
						textarea { 
							min-height: 80px; font-size: 14px; line-height: 16px;   
						}
					}
				}
			}
			
		}

		.form-std {
			.col {margin-bottom: 15px;}
		}

		.list-career {
			.row {
				padding: 15px 0 0 0;
				.col {
					min-height: 225px; padding: 30px 15px; 
					.lokasi {
						font-size: 12px; padding: 0 0 0 35px; 
						&:before {
							width:20px; 
						}
					}  
					.title {font-size: 18px; line-height: 24px;}  
					.btn {left: 15px; bottom: 15px;}  
				}
			}
		}

	}

	footer {
		.logo-foot {width: 180px;}  
		.nav-foot {
			margin: 8px 0 3px 0;  
			ul {
				li {
					a {font-size: 10px; padding: 0 3px;} 
				}
			}
		}
		.copyright {font-size: 10px;} 
	}

	header, footer, .middle {padding-left: 60px;}      
	.comingsoon {
		.container {width: 720px; padding: 0 50px;}      
	}

	#boxPop {
		min-width: 600px; max-width: 600px; min-height: 380px;
		.content_pop {
			min-height: 380px;
			.sc_content {
				min-height: 370px;
				.detTxt {
					padding: 0 0 0 30px;
					.detYear {font-size: 18px; margin-bottom: 20px;} 
					.detTitle {font-size: 32px; margin-bottom: 20px;} 
					.detDes {
						padding-top: 10px; height: 210px; font-size: 15px;
						.contentScroll {min-height: 210px; max-height: 210px;} 
					}   
				} 
			}
		} 
	}  
	
	
}